import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Button from './Button';

import css from './Search.module.css';

const searchEngineUrl = 'https://duckduckgo.com';

const propTypes = {

};

const defaultProps = {

};

function Search() {
  const {
    site: {
      siteMetadata: {
        siteUrl,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  const [input, setInput] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    const searchString = `site:${siteUrl} ${input}`;
    if (typeof window !== 'undefined') {
      window.location.href = `${searchEngineUrl}?q=${encodeURIComponent(searchString)}`;
    }
  };

  return (
    <form
      role="search"
      className={`ft-component ${css.search}`}
      onSubmit={handleSubmit}
    >
      <label htmlFor="search">
        <span className="ft-visually-hidden">Suche mit DuckDuckGo.com nach:</span>
        <input
          id="search"
          type="search"
          className={css.search__input}
          name="q"
          placeholder="via DuckDuckGo.com …"
          onChange={(event) => setInput(event.target.value)}
        />
      </label>
      <Button isSubmit className={css.search__button}>Suchen</Button>
    </form>
  );
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default Search;
