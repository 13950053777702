import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import JumpLinks from './JumpLinks';
import Header from './Header';
import Footer from './Footer';
import Divider from './Divider';

import css from './Layout.module.css';

const propTypes = {
  children: PropTypes.node,
  printH1: PropTypes.bool,
};

const defaultProps = {
  children: null,
  printH1: false,
};

function Layout({ printH1, children }) {
  const {
    site: {
      siteMetadata: {
        title,
        description,
      },
    },
    firstMenu,
    secondMenu,
    thirdMenu,
    fourthMenu,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      # TODO: Menu ID is hardcoded, is there a way to use gatsby-config for that?
      firstMenu: wordpressWpApiMenusMenusItems(slug: {eq: "focus-theme-1"}) {
        items {
          url
          title
        }
      }
      secondMenu: wordpressWpApiMenusMenusItems(slug: {eq: "focus-theme-2"}) {
        items {
          url
          title
        }
      }
      thirdMenu: wordpressWpApiMenusMenusItems(slug: {eq: "focus-theme-3"}) {
        items {
          url
          title
        }
      }
      fourthMenu: wordpressWpApiMenusMenusItems(slug: {eq: "focus-theme-4"}) {
        items {
          url
          title
        }
      }
    }
  `);

  return (
    <div className={`ft-component ${css.layout} ft-gap-margin ft-gap-margin--col ft-gap-margin--lg`}>
      <JumpLinks />
      <Header
        printH1={printH1}
        siteTitle={title}
        siteDescription={description}
      />
      <main id="content" className={`${css.layout__main} ft-gap-margin ft-gap-margin--col ft-gap-margin--xl`}>
        {children}
      </main>
      <div className={css.layout__alignBottom}>
        <Divider />
        <Footer
          siteTitle={title}
          firstMenu={firstMenu}
          secondMenu={secondMenu}
          thirdMenu={thirdMenu}
          fourthMenu={fourthMenu}
        />
      </div>
    </div>
  );
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
