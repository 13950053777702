import React from 'react';

import DividerIcon from '../images/gull.inline.svg';
import css from './Divider.module.css';

function Divider() {
  return (
    <div className={`ft-component ${css.divider} ft-svg-path-currentColor`} role="separator">
      <DividerIcon />
    </div>
  );
}

export default Divider;
