import React from 'react';
import PropTypes from 'prop-types';

import Link from './Link';

import css from './SlashList.module.css';

const propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }).isRequired,
  ).isRequired,
  linkClassName: PropTypes.string,
};

const defaultProps = {
  linkClassName: '',
};

function SlashList({ categories, linkClassName }) {
  if (!categories || categories.length === 0) {
    return null;
  }
  return (
    <ul className={`ft-component ${css.slashList} ft-list-reset`}>
      {categories.map(({ url, label }) => (
        <li key={url}>
          <Link className={linkClassName} to={url}>
            {label}
          </Link>
        </li>
      ))}
    </ul>
  );
}

SlashList.propTypes = propTypes;
SlashList.defaultProps = defaultProps;

export default SlashList;
