import React from 'react';
import PropTypes from 'prop-types';

import Logo from './Logo';
import SlashList from './SlashList';
import Search from './Search';

import css from './Footer.module.css';

const propTypes = {
  siteTitle: PropTypes.string.isRequired,
  firstMenu: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
  secondMenu: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
  thirdMenu: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
  fourthMenu: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
};

const defaultProps = {
  firstMenu: null,
  secondMenu: null,
  thirdMenu: null,
  fourthMenu: null,
};

function Footer({
  siteTitle, firstMenu, secondMenu, thirdMenu, fourthMenu,
}) {
  return (
    <footer className={`ft-component ${css.footer}`}>
      <div className="ft-grid ft-gap-margin ft-gap-margin--col ft-gap-margin--md">
        <h2>
          <Logo siteTitle={siteTitle} />
        </h2>
        {(firstMenu || secondMenu || thirdMenu || fourthMenu) && (
          <nav className="ft-flex ft-flex--col ft-gap-margin ft-gap-margin--col ft-gap-margin--sm">
            <h2 className="ft-visually-hidden">Hauptmenü</h2>
            {firstMenu && firstMenu.items && firstMenu.items.length > 0 && (
              <SlashList
                categories={firstMenu.items.map(({ url, title: label }) => ({
                  url,
                  label,
                }))}
              />
            )}
            {secondMenu && secondMenu.items && secondMenu.items.length > 0 && (
              <SlashList
                categories={secondMenu.items.map(({ url, title: label }) => ({
                  url,
                  label,
                }))}
              />
            )}
            {thirdMenu && thirdMenu.items && thirdMenu.items.length > 0 && (
              <div className="ft-font--sm">
                <SlashList
                  categories={thirdMenu.items.map(({ url, title: label }) => ({
                    url,
                    label,
                  }))}
                />
              </div>
            )}
            {fourthMenu && fourthMenu.items && fourthMenu.items.length > 0 && (
              <div className={`${css.footer__lastMenu} ft-font--sm`}>
                <SlashList
                  categories={fourthMenu.items.map(({ url, title: label }) => ({
                    url,
                    label,
                  }))}
                />
              </div>
            )}
          </nav>
        )}
        <div className={css.footer__search}>
          <Search />
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
