import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { addTrailingSlash } from '../utils/helper';

const propTypes = {
  path: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  useTitleTemplate: PropTypes.bool,
  created: PropTypes.string,
  updated: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  topLevelSchemata: PropTypes.arrayOf(PropTypes.object.isRequired),
  next: PropTypes.string,
  prev: PropTypes.string,
};

const defaultProps = {
  path: '',
  description: '',
  lang: 'de-DE',
  type: 'website',
  useTitleTemplate: true,
  created: '',
  updated: '',
  image: null,
  topLevelSchemata: [],
  next: null,
  prev: null,
};

function Helmet({
  path,
  description,
  lang,
  title,
  type,
  useTitleTemplate,
  created,
  updated,
  image,
  topLevelSchemata,
  next,
  prev,
}) {
  const {
    site: {
      siteMetadata: {
        title: siteTitle,
        description: defaultDescription,
        siteUrl,
        author,
        socialMedia: {
          facebookPageUrl,
          facebookPageIds,
          twitterSiteName,
          twitterCreatorName,
          twitterUrl,
          instagramUrl,
        },
        theme,
      },
    },
    siteImageFile: {
      childImageSharp: {
        original: siteImage,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              path
            }
            socialMedia {
              facebookPageUrl
              facebookPageIds
              twitterSiteName
              twitterCreatorName
              twitterUrl
              instagramUrl
            }
            theme
          }
        }
        # TODO: Path is hardcoded, is there a way to use gatsby-config for that?
        siteImageFile: file(relativePath: {eq: "site-image.png"}) {
          childImageSharp {
            original {
              height
              src
              width
            }
          }
        }
      }
    `,
  );

  const url = path ? addTrailingSlash(`${siteUrl}${path}`) : null;
  const metaDescription = description || defaultDescription;
  const metaImage = image || {
    ...siteImage,
    src: `${siteUrl}${siteImage.src}`,
  };

  const defaultTopLevelSchema = [
    {
      '@type': 'Organization',
      '@id': '#publisher',
      name: siteTitle,
      url: siteUrl,
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${siteImage.src}`,
        height: siteImage.height,
        width: siteImage.width,
      },
      sameAs: [
        ...(facebookPageUrl ? [facebookPageUrl] : []),
        ...(twitterUrl ? [twitterUrl] : []),
        ...(instagramUrl ? [instagramUrl] : []),
      ],
    },
    {
      '@type': 'Person',
      '@id': '#author',
      name: author.name,
      url: `${siteUrl}${author.path}`,
    },
  ];

  const schema = {
    '@context': 'http://schema.org',
    '@graph':
    [
      ...defaultTopLevelSchema,
      ...topLevelSchemata,
    ],
  };

  // Set main theme CSS properties.
  let themeStyle = null;
  if (!theme || theme === 1) {
    themeStyle = {
      '--ft-color--primary': 'var(--ft-color--theme-1-primary)',
      '--ft-color--secondary': 'var(--ft-color--theme-1-secondary)',
      '--ft-color--secondary-dimmed': 'var(--ft-color--theme-1-secondary-dimmed)',
    };
  } else if (theme && theme === 2) {
    themeStyle = {
      '--ft-color--primary': 'var(--ft-color--theme-2-primary)',
      '--ft-color--secondary': 'var(--ft-color--theme-2-secondary)',
      '--ft-color--secondary-dimmed': 'var(--ft-color--theme-2-secondary-dimmed)',
    };
  }

  // Workaround for react-helmet bug,
  // @see https://github.com/nfl/react-helmet/issues/344
  if (typeof window !== 'undefined') {
    themeStyle = Object.keys(themeStyle).map((key) => `${key}: ${themeStyle[key]}`).join(';');
  }

  return (
    <ReactHelmet titleTemplate={useTitleTemplate ? `%s @ ${siteTitle}` : '%s'}>
      <html
        lang={lang}
        prefix="og: http://ogp.me/ns# article: http://ogp.me/ns/article#"
        style={themeStyle}
      />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      {created && <meta name="date" content={created} />}
      {updated && <meta name="last-modified" content={updated} />}
      {url && <link rel="canonical" href={url} />}
      {next && <link rel="next" href={next} />}
      {prev && <link rel="prev" href={prev} />}

      <meta property="og:locale" content={lang.replace('-', '_')} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      {url && <meta property="og:url" content={url} />}
      {updated && <meta property="og:updated_time" content={updated} />}
      {metaImage && metaImage.src && <meta property="og:image" content={metaImage.src} />}
      {metaImage && metaImage.height && <meta property="og:image:height" content={metaImage.height} />}
      {metaImage && metaImage.width && <meta property="og:image:width" content={metaImage.width} />}

      {facebookPageUrl && <meta property="article:publisher" content={facebookPageUrl} />}
      {facebookPageIds && <meta property="fb:pages" content={facebookPageIds} />}

      <meta name="twitter:card" content="summary" />
      {twitterSiteName && <meta name="twitter:site" content={twitterSiteName} />}
      {twitterCreatorName && <meta name="twitter:creator" content={twitterCreatorName} />}

      <meta name="theme-color" media="(prefers-color-scheme: light)" content="hsl(0, 0%, 100%)" />
      <meta name="theme-color" media="(prefers-color-scheme: dark)" content="hsl(0, 0%, 12.94%)" />

      {schema && <script type="application/ld+json">{JSON.stringify(schema, null, 2)}</script>}
    </ReactHelmet>
  );
}

Helmet.propTypes = propTypes;
Helmet.defaultProps = defaultProps;

export default Helmet;
