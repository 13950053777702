import React from 'react';
import PropTypes from 'prop-types';

import Link from './Link';
import LogoImage from '../images/logo.inline.svg';
import LogoTextImage from '../images/logo-text.inline.svg';

import css from './Logo.module.css';

const propTypes = {
  siteTitle: PropTypes.string.isRequired,
  showLogoText: PropTypes.bool,
};

const defaultProps = {
  showLogoText: true,
};

function Logo({ siteTitle, showLogoText }) {
  return (
    <Link
      to="/"
      className={`ft-component ${css.logo} ft-flex ft-flex--row ft-gap-margin ft-gap-margin--row ft-svg-path-currentColor`}
    >
      <span className="ft-visually-hidden">{siteTitle}</span>
      <LogoImage />
      {showLogoText && (
        <LogoTextImage />
      )}
    </Link>
  );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
