import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';

const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
};

const defaultProps = {
  activeClassName: null,
  partiallyActive: null,
  rel: null,
  target: null,
};

/**
 * This components decides, whether to use the Gatsby Link component for internal links
 * or the <a> tag for external links.
 *
 * @see https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
 */

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
function Link({
  children, to, activeClassName, partiallyActive, rel, target, ...other
}) {
  const {
    site: {
      siteMetadata: {
        siteUrl,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  // An internal link is assumed to start with exactly one slash
  // or siteUrl, anything else is assumed external.
  const pattern = new RegExp(`^${siteUrl}/?`);
  const toNew = to.replace(pattern, '/');
  const isInternal = !toNew.match(/^https?:\/\//);
  const isFile = toNew.match(/\.[0-9a-z]+$/i);
  // Gatsby Link cannot handle anchor or query links.
  // @see https://www.gatsbyjs.org/docs/gatsby-link/#recommendations-for-programmatic-in-app-navigation
  const isQueryOrAnchor = toNew.match(/^[#?]/);

  // Use Gatsby Link for internal non-file links.
  if (isInternal && !isFile && !isQueryOrAnchor) {
    return (
      <GatsbyLink
        to={toNew}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        target={target}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }

  // Everything else gets a standard <a> tag.
  if (!isInternal) {
    // @see https://developers.google.com/web/tools/lighthouse/audits/noopener
    const defaultExternalRel = 'noreferrer noopener external';
    const defaultExternalTarget = '_blank';
    const props = {
      rel: rel ? `${defaultExternalRel} ${rel}` : defaultExternalRel,
      target: target || defaultExternalTarget,
      ...other,
    };
    return <a href={toNew} {...props}>{children}</a>;
  }

  const props = {
    ...(rel && { rel }),
    ...(target && { target }),
    ...other,
  };

  return <a href={toNew} {...props}>{children}</a>;
}

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
