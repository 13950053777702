import React from 'react';

import css from './JumpLinks.module.css';

function JumpLinks() {
  return (
    <div className={`ft-component ${css.jumpLinks} ft-grid ft-font--sm ft-gap-margin__exclude`}>
      <a className="ft-visually-hidden" href="#content">
      Weiter zum Inhalt
      </a>
    </div>
  );
}

export default JumpLinks;
