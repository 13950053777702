import React from 'react';
import PropTypes from 'prop-types';

import Logo from './Logo';

import css from './Header.module.css';

const propTypes = {
  siteTitle: PropTypes.string.isRequired,
  siteDescription: PropTypes.string.isRequired,
  printH1: PropTypes.bool,
};

const defaultProps = {
  printH1: false,
};

function Header({
  siteTitle, siteDescription, printH1,
}) {
  if (printH1) {
    return (
      <>
        <header className={`ft-component ${css.header} ft-grid ft-gap-margin`}>
          <h1><Logo siteTitle={siteTitle} /></h1>
          <h2 className="ft-visually-hidden ft-gap-margin__exclude">{siteDescription}</h2>
        </header>
      </>
    );
  }
  return (
    <header className={`ft-component ${css.header} ft-grid ft-gap-margin`}>
      <p><Logo siteTitle={siteTitle} showLogoText={false} /></p>
      <p className="ft-visually-hidden ft-gap-margin__exclude">{siteDescription}</p>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
