export const addTrailingSlash = (url) => url.replace(/\/?$/, '/');

export const appendJs = (src) => {
  if (typeof window === 'undefined') {
    return;
  }
  const s = document.createElement('script');
  s.defer = 'defer';
  s.src = src;
  document.getElementsByTagName('head')[0].appendChild(s);
};
