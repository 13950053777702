import React from 'react';
import PropTypes from 'prop-types';

import css from './Button.module.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSubmit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

const defaultProps = {
  className: null,
  isLoading: false,
  isSmall: false,
  isSubmit: false,
  disabled: false,
  onClick: undefined,
  title: null,
};

function Button({
  children,
  className,
  isLoading,
  isSmall,
  isSubmit,
  disabled,
  onClick,
  title,
}) {
  const classNames = [css.button];
  if (className) {
    classNames.push(className);
  }

  if (isLoading) {
    classNames.push(css.buttonLoading);
  }

  if (isSmall) {
    classNames.push('ft-font--sm');
    classNames.push(css.buttonSm);
  }

  return (
    <button
      className={classNames.join(' ')}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled || isLoading}
      onClick={onClick}
      title={title}
    >
      <span className={css.button__content}>{children}</span>
      {isLoading && (
        <span className={css.button__centerizer}>
          <span className={css.button__animation}>
            <span />
            <span />
            <span />
            <span />
          </span>
        </span>
      )}
    </button>
  );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
